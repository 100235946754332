import React from 'react'
import ReduxSagaLogo from '../../images/ReduxSagaLogo.png';


const ReduxSagaImage = () => {
    return (
        <>
            <img src={ReduxSagaLogo} alt="Redux-Saga" width="160px" style={{margin: "0 10px"}} />

        </>
    )
}

export default ReduxSagaImage
