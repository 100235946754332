import React from 'react'
import PostgresLogo from '../../images/PostgresLogo.png';


const PostgresImage = () => {
    return (
        <>
            <img src={PostgresLogo} alt="PostgreSQL" width="160px" style={{margin: "0 10px"}} />

        </>
    )
}

export default PostgresImage
