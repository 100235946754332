import React from 'react'
import html5 from '../../images/html5logo.png';


const HtmlImage = () => {
    return (
        <>
            <img src={html5} alt="HTML" width="160px" style={{margin: "0 10px"}} />

        </>
    )
}

export default HtmlImage
