import React from 'react'
import materialUiLogo from '../../images/materialUiLogo.png';


const MaterialUiImage = () => {
    return (
        <>
            <img src={materialUiLogo} alt="MUI" width="160px" style={{margin: "0 10px"}} />

        </>
    )
}

export default MaterialUiImage
