import React from 'react'
import gitLogo from '../../images/gitLogo.png';


const GitImage = () => {
    return (
        <>
            <img src={gitLogo} alt="Git" width="160px" style={{margin: "0 10px"}} />

        </>
    )
}

export default GitImage
