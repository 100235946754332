import React from 'react'
import PassportLogo from '../../images/PassportLogo.png';


const PassportImage = () => {
    return (
        <>
            <img src={PassportLogo} alt="Passport" height="160px" style={{margin: "0 10px"}} />

        </>
    )
}

export default PassportImage
