import React from 'react'
import phpLogo from '../../images/phpLogo.png';


const PhpImage = () => {
    return (
        <>
            <img src={phpLogo} alt="PHP" width="160px" style={{margin: "0 10px"}} />

        </>
    )
}

export default PhpImage
