import React from 'react'
import cssLogo from '../../images/cssLogo.png';


const CssImage = () => {
    return (
        <>
            <img src={cssLogo} alt="CSS" width="160px" style={{margin: "0 10px"}} />

        </>
    )
}

export default CssImage
