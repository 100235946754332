import React from 'react'
import expressLogo from '../../images/expressLogo.png';


const ExpressImage = () => {
    return (
        <>
            <img src={expressLogo} alt="Express" width="160px" style={{margin: "0 10px"}} />

        </>
    )
}

export default ExpressImage
