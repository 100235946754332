import React from 'react'
import javascript from '../../images/js-logo.png';


const JavascriptImage = () => {
    return (
        <>
            <img src={javascript} alt="JavaScript" width="160px" style={{margin: "0 10px"}} />
        </>
    )
}

export default JavascriptImage
