import React from 'react'
import react from '../../images/reactIcon.png';


const ReactImage = () => {
    return (
        <>
            <img src={react} alt="React" width="160px" style={{margin: "0 10px"}} />
        </>
    )
}

export default ReactImage
