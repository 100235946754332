import React from 'react'
import node from '../../images/nodeLogo.png';


const NodeImage = () => {
    return (
        <>
            <img src={node} alt="Node" width="160px" style={{margin: "0 10px"}} />

        </>
    )
}

export default NodeImage
